import * as React from "react";
import { useParams, useLocation, useNavigate } from "react-router";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Avatar from "@mui/joy/Avatar";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import Tooltip from "@mui/joy/Tooltip";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import ListDivider from "@mui/joy/ListDivider";
import Drawer from "@mui/joy/Drawer";
import ModalClose from "@mui/joy/ModalClose";
import DialogTitle from "@mui/joy/DialogTitle";
import List from "@mui/joy/List";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";

import Logo from "../../assets/logo-nomargins.png";
import Receipt from "@mui/icons-material/Receipt";
import Link from "@mui/icons-material/Link";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import BookRoundedIcon from "@mui/icons-material/BookRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

export function Header({ uid }) {
  const [open, setOpen] = React.useState(false);
  // const { uid } = useParams();
  const location = useLocation();

  const navigation = [
    {
      name: "Checkout",
      current: true,
      path: `/checkout/${uid ? uid : ""}`,
    },
    {
      name: "Payment History",
      current: false,
      path: `/payments/${uid ? uid : ""}`,
    },
  ];

  return (
    <Box
      sx={{
        p: 2,
        gap: 2,
        bgcolor: "background.surface",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gridColumn: "1 / -1",
        borderBottom: "1px solid",
        borderColor: "divider",
        position: "sticky",
        top: 0,
        zIndex: 1100,
        displayPrint: "none",
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <Box
          sx={{
            gap: 2,
            display: "flex",
            alignItems: "center",
            paddingRight: 5,
          }}
        >
          <a href="http://wuc.com.kw">
            <img
              src={Logo}
              alt="Warba United Logo"
              style={{ width: "auto", height: 32 }}
            />
          </a>
        </Box>
        {navigation.map((topBarItem) => (
          <Button
            key={topBarItem.name}
            variant="plain"
            color="neutral"
            aria-pressed={
              location.pathname.includes(topBarItem.path) &&
              location.pathname == topBarItem.path
                ? "true"
                : "false"
            }
            component={
              location.pathname.includes(topBarItem.path) &&
              location.pathname == topBarItem.path
                ? ""
                : "a"
            }
            href={
              location.pathname.includes(topBarItem.path) &&
              location.pathname == topBarItem.path
                ? ""
                : topBarItem.path
            }
            size="sm"
            sx={{
              alignSelf: "center",
              "&:hover": {
                cursor:
                  location.pathname.includes(topBarItem.path) &&
                  location.pathname == topBarItem.path
                    ? "default"
                    : "pointer",
              },
            }}
          >
            {topBarItem.name}
          </Button>
        ))}
      </Stack>
      <Box
        sx={{
          display: {
            xs: "inline-flex",
            sm: "none",
          },
        }}
      >
        <IconButton
          variant="plain"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <MenuRoundedIcon />
        </IconButton>
        <Drawer
          sx={{ display: { xs: "inline-flex", sm: "none" } }}
          open={open}
          onClose={() => setOpen(false)}
        >
          <ModalClose />
          <DialogTitle>Warba United Co.</DialogTitle>
          <Box sx={{ px: 1 }}>
            <List
              size="sm"
              sx={{
                "--ListItem-radius": "var(--joy-radius-sm)",
                "--List-gap": "4px",
              }}
            >
              <ListItem nested>
                <ListSubheader sx={{ letterSpacing: "2px", fontWeight: "800" }}>
                  Browse
                </ListSubheader>
                <List
                  aria-labelledby="nav-list-browse"
                  sx={{
                    "& .JoyListItemButton-root": { p: "8px" },
                  }}
                >
                  {navigation.map((topBarItem, index) => (
                    <React.Fragment key={topBarItem.name}>
                      <ListItem
                        component="a"
                        href={
                          location.pathname.includes(topBarItem.path)
                            ? ""
                            : topBarItem.path
                        }
                      >
                        <ListItemButton
                          selected={
                            location.pathname.includes(topBarItem.path)
                              ? true
                              : false
                          }
                        >
                          <ListItemDecorator>
                            <Link fontSize="small" />
                          </ListItemDecorator>
                          <ListItemContent>{topBarItem.name}</ListItemContent>
                        </ListItemButton>
                      </ListItem>
                      {index === navigation.length - 1 ? null : (
                        <ListDivider inset="gutter" />
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </ListItem>
            </List>
          </Box>
        </Drawer>

        <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
          <img
            src={Logo}
            alt="Warba United Logo"
            style={{ width: "auto", height: 32 }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1.5,
          alignItems: "center",
        }}
      >
        {/* <Input
          size="sm"
          variant="outlined"
          placeholder="Search anything…"
          startDecorator={<SearchRoundedIcon color="primary" />}
          endDecorator={
            <IconButton
              variant="outlined"
              color="neutral"
              sx={{ bgcolor: "background.level1" }}
            >
              <Typography level="title-sm" textColor="text.icon">
                ⌘ K
              </Typography>
            </IconButton>
          }
          sx={{
            alignSelf: "center",
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        /> */}
        {/* <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          sx={{
            display: { xs: "inline-flex", sm: "none" },
            alignSelf: "center",
          }}
        >
          <SearchRoundedIcon />
        </IconButton> */}
        {/* <Tooltip title="Joy UI overview" variant="outlined">
          <IconButton
            size="sm"
            variant="plain"
            color="neutral"
            component="a"
            href="/blog/first-look-at-joy/"
            sx={{ alignSelf: "center" }}
          >
            <BookRoundedIcon />
          </IconButton>
        </Tooltip> */}
        {/* <ColorSchemeToggle /> */}
        {/* <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{
              maxWidth: "32px",
              maxHeight: "32px",
              borderRadius: "9999999px",
            }}
          >
            <Avatar
              src="https://i.pravatar.cc/40?img=2"
              srcSet="https://i.pravatar.cc/80?img=2"
              sx={{ maxWidth: "32px", maxHeight: "32px" }}
            />
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: "99999",
              p: 1,
              gap: 1,
              "--ListItem-radius": "var(--joy-radius-sm)",
            }}
          >
            <MenuItem>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src="https://i.pravatar.cc/40?img=2"
                  srcSet="https://i.pravatar.cc/80?img=2"
                  sx={{ borderRadius: "50%" }}
                />
                <Box sx={{ ml: 1.5 }}>
                  <Typography level="title-sm" textColor="text.primary">
                    Rick Sanchez
                  </Typography>
                  <Typography level="body-xs" textColor="text.tertiary">
                    rick@email.com
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <ListDivider />
            <MenuItem>
              <HelpRoundedIcon />
              Help
            </MenuItem>
            <MenuItem>
              <SettingsRoundedIcon />
              Settings
            </MenuItem>
            <ListDivider />
            <MenuItem component="a" href="/blog/first-look-at-joy/">
              First look at Joy UI
              <OpenInNewRoundedIcon />
            </MenuItem>
            <MenuItem
              component="a"
              href="https://github.com/mui/material-ui/tree/master/docs/data/joy/getting-started/templates/email"
            >
              Sourcecode
              <OpenInNewRoundedIcon />
            </MenuItem>
            <ListDivider />
            <MenuItem>
              <LogoutRoundedIcon />
              Log out
            </MenuItem>
          </Menu>
        </Dropdown> */}
      </Box>
    </Box>
  );
}
